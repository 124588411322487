import React from "react"
// gatsby libraries
import { GatsbyImage } from "gatsby-plugin-image"
// nodejs library that concatenates classes
import classNames from "classnames"
// nodejs library to set properties for components
import PropTypes from "prop-types"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button"
import Hidden from "@material-ui/core/Hidden"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
// @material-ui/icons
import Menu from "@material-ui/icons/Menu"
import Close from "@material-ui/icons/Close"
// core components
import styles from "assets/jss/material-kit-pro-react/components/headerStyle.jsx"

const useStyles = makeStyles(styles)

export default function Header(props) {
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [lightLogo, setLightLogo] = React.useState(true)
  const classes = useStyles()
  React.useEffect(() => {
    if (props.changeColorOnScroll && typeof window !== "undefined") {
      window.addEventListener("scroll", headerColorChange)
    }
    return function cleanup() {
      if (props.changeColorOnScroll && typeof window !== "undefined") {
        window.removeEventListener("scroll", headerColorChange)
      }
    }
  })
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props

    const windowsScrollTop = typeof window !== "undefined" && window.pageYOffset
    if (windowsScrollTop > changeColorOnScroll.height) {
      setLightLogo(false)
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color])
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color])
    } else {
      setLightLogo(true)
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color])
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color])
    }
  }
  const {
    color,
    links,
    brand,
    fixed,
    absolute,
    logoImageLight,
    logoImageDark,
  } = props
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
    [classes.logoImageLight]: logoImageLight,
    [classes.logoImageDark]: logoImageDark,
  })
  return (
    <AppBar id="navbar" className={appBarClasses}>
      <Toolbar className={classes.container}>
        <Button href="/" className={classes.title}>
          {lightLogo ? (
            <GatsbyImage
              image={logoImageLight}
              className={classes.chilimaniaLogo}
              objectFit={"contain"}
              objectPosition={"left"}
              alt={brand}
            />
          ) : (
            <GatsbyImage
              image={logoImageDark}
              className={classes.chilimaniaLogo}
              objectFit={"contain"}
              objectPosition={"left"}
              alt={brand}
            />
          )}
        </Button>
        <Hidden mdDown implementation="css" className={classes.hidden}>
          <div className={classes.collapse}>{links}</div>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Hidden lgUp implementation="js">
        <SwipeableDrawer
          variant="temporary"
          anchor={"right"}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={handleDrawerToggle}
          onOpen={handleDrawerToggle}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            className={classes.closeButtonDrawer}
          >
            <Close />
          </IconButton>
          <div className={classes.appResponsive}>{links}</div>
        </SwipeableDrawer>
      </Hidden>
    </AppBar>
  )
}

Header.defaultProp = {
  color: "white",
}

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark",
  ]),
  links: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  logoImageLight: PropTypes.object,
  logoImageDark: PropTypes.object,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark",
    ]).isRequired,
  }),
}
