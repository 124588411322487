import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Slide from "@material-ui/core/Slide"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// @material-ui/icons
import Close from "@material-ui/icons/Close"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff"
// page styles
import privacyTermsStyle from "assets/jss/material-kit-pro-react/components/privacyTermsStyle.jsx"
// core components
import Button from "components/CustomButtons/Button.jsx"
import React from "react"

const useStyles = makeStyles(privacyTermsStyle)

// page modal functions
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})
Transition.displayName = "Transition"

export default function PrivacyTerms() {
  const [privacyTermsModal, setPrivacyTermsModal] = React.useState(false)
  const [scroll, setScroll] = React.useState("body")
  const classes = useStyles()

  return (
    <>
      <Button
        size="sm"
        className={classes.privacyTermsButton}
        href=""
        color="primary"
        onClick={() => setPrivacyTermsModal(true) && setScroll("body")}
        simple
      >
        Privacy Policy &amp; Terms of Service
      </Button>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal + " " + classes.modalLarge,
        }}
        open={privacyTermsModal}
        TransitionComponent={Transition}
        scroll={scroll}
        onClose={() => setPrivacyTermsModal(false)}
        aria-labelledby="privacy-terms-modal-slide-title"
        aria-describedby="privacy-terms-modal-slide-description"
      >
        <DialogTitle
          id="privacy-terms-modal-slide-title"
          disableTypography
          className={classes.modalHeaderBordered}
        >
          <Button
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={() => setPrivacyTermsModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h6 className={classes.modalCardCategory}>
            <VisibilityOffIcon className={classes.cardIcon} />
            Privacy Policy &amp; Terms of Service
          </h6>
        </DialogTitle>
        <DialogContent
          id="privacy-terms-modal-slide-description"
          className={classes.modalBody}
        >
          <h3 className={classes.modalPrivacyTermsMapTitle}>
            Privacy Policy &amp; Terms of Service
          </h3>
          <div className={classes.description}>
            <h4 className={classes.title}>Terms of Service</h4>
            <ol>
              <li>
                <b>Terms</b>
                <br />
                By accessing the website at https://www.chilimania.com, you are
                agreeing to be bound by these terms of service, all applicable
                laws and regulations, and agree that you are responsible for
                compliance with any applicable local laws. If you do not agree
                with any of these terms, you are prohibited from using or
                accessing this site. The materials contained in this website are
                protected by applicable copyright and trademark law.
              </li>
              <li>
                <b>Use License</b>
                <br />
                Permission is granted to temporarily download one copy of the
                materials (information or software) on Chilimania, Inc.’s
                website for personal, non-commercial transitory viewing only.
                This is the grant of a license, not a transfer of title, and
                under this license you may not: modify or copy the materials;
                use the materials for any commercial purpose, or for any public
                display (commercial or non-commercial); attempt to decompile or
                reverse engineer any software contained on Chilimania, Inc.’s
                website; remove any copyright or other proprietary notations
                from the materials; or transfer the materials to another person
                or “mirror” the materials on any other server. This license
                shall automatically terminate if you violate any of these
                restrictions and may be terminated by Chilimania, Inc. at any
                time. Upon terminating your viewing of these materials or upon
                the termination of this license, you must destroy any downloaded
                materials in your possession whether in electronic or printed
                format.{" "}
              </li>
              <li>
                <b>Disclaimer</b>
                <br />
                The materials on Chilimania, Inc.’s website are provided on an
                ‘as is’ basis. Chilimania, Inc. makes no warranties, expressed
                or implied, and hereby disclaims and negates all other
                warranties including, without limitation, implied warranties or
                conditions of merchantability, fitness for a particular purpose,
                or non-infringement of intellectual property or other violation
                of rights. Further, Chilimania, Inc. does not warrant or make
                any representations concerning the accuracy, likely results, or
                reliability of the use of the materials on its website or
                otherwise relating to such materials or on any sites linked to
                this site.{" "}
              </li>
              <li>
                <b>Limitations</b>
                <br />
                In no event shall Chilimania, Inc. or its suppliers be liable
                for any damages (including, without limitation, damages for loss
                of data or profit, or due to business interruption) arising out
                of the use or inability to use the materials on Chilimania,
                Inc.’s website, even if Chilimania, Inc. or a Chilimania, Inc.
                authorized representative has been notified orally or in writing
                of the possibility of such damage. Because some jurisdictions do
                not allow limitations on implied warranties, or limitations of
                liability for consequential or incidental damages, these
                limitations may not apply to you.
              </li>
              <li>
                <b>Accuracy of materials</b>
                <br />
                The materials appearing on Chilimania, Inc.’s website could
                include technical, typographical, or photographic errors.
                Chilimania, Inc. does not warrant that any of the materials on
                its website are accurate, complete or current. Chilimania, Inc.
                may make changes to the materials contained on its website at
                any time without notice. However Chilimania, Inc. does not make
                any commitment to update the materials.
              </li>
              <li>
                <b>Links</b>
                <br />
                Chilimania, Inc. has not reviewed all of the sites linked to its
                website and is not responsible for the contents of any such
                linked site. The inclusion of any link does not imply
                endorsement by Chilimania, Inc. of the site. Use of any such
                linked website is at the user’s own risk.
              </li>
              <li>
                <b>Modifications</b>
                <br />
                Chilimania, Inc. may revise these terms of service for its
                website at any time without notice. By using this website you
                are agreeing to be bound by the then current version of these
                terms of service.
              </li>
              <li>
                <b>Governing Law</b>
                <br />
                These terms and conditions are governed by and construed in
                accordance with the laws of Wisconsin and you irrevocably submit
                to the exclusive jurisdiction of the courts in that State or
                location.
              </li>
            </ol>
            <h4 className={classes.title}>Privacy Policy</h4>
            <p>
              Your privacy is important to us.
              <br />
              <br />
              We don’t ask for your personal information unless we truly need
              it.
              <br />
              <br />
              We don’t share your personal information with third-parties,
              except where required by law.
              <br />
              <br />
              We don’t store your personal information on our servers unless
              it’s required for providing a service to you.
              <br />
              <br />
              It is Chilimania, Inc.’s policy to respect your privacy regarding
              any information we may collect from you across our website,{" "}
              https://www.chilimania.com.
            </p>
            <h4 className={classes.title}>Log Data</h4>
            <p>
              We log general information about your browser whenever you visit
              our website. This information may include your computer’s Internet
              Protocol (IP) address, your browser type and version, the pages
              you visit on our site, the time and date of your visit, and the
              time spent on each page.
              <br />
              <br />
              Like most website operators, we collect this data to better
              understand how our visitors use our services, and how we may
              improve your experience of our website in future. We do not share
              data specifics publicly, but may disclose aggregated and
              anonymised versions of this information, eg. in website usage
              trend reports.
            </p>
            <h4 className={classes.title}>Cookies</h4>
            <p>
              We use “cookies” to collect information about you and your
              activity across our site. A cookie is a small piece of data that
              our website stores on your computer, and accesses each time you
              visit so we can understand how you use our site and serve you
              content based on preferences you have specified.
              <br />
              <br />
              If you do not wish to accept cookies from us, you should instruct
              your browser to refuse cookies from our website, with the
              understanding that we may be unable to provide you with some of
              your desired service without them. This policy covers only the use
              of cookies between your computer and our website; it does not
              cover the use of cookies by any advertisers.
            </p>
            <h4 className={classes.title}>Third-Party Services</h4>
            <p>
              We may employ third-party companies and individuals on our
              websites – for example, analytics providers and content partners.
              These third parties have access to your personal information only
              to perform specific tasks on our behalf, and are obligated not to
              disclose or use it for any other purpose.
            </p>
            <h4 className={classes.title}>Security</h4>
            <p>
              We take security seriously, and do what we can within commercially
              acceptable means to protect your personal information from loss or
              theft, as well as unauthorized access, disclosure, copying, use or
              modification. That said, we advise that no method of electronic
              transmission or storage is 100% secure, and cannot guarantee the
              absolute security of your data.
            </p>
            <h4 className={classes.title}>Links to Other Sites</h4>
            <p>
              Our website may link to external sites that are not operated by
              us. Please be aware that we have no control over the content and
              practices of these sites, and cannot assume responsibility for
              their treatment of your personal information. This privacy policy
              only covers our website and privacy practices.
            </p>
            <h4 className={classes.title}>Children’s Privacy</h4>
            <p>
              We do not knowingly collect or store personal information from
              children (visitors under the age of 13). If you believe your child
              has provided us with personal information, we encourage you to
              contact us immediately, and we will do our best to delete the data
              as quickly as possible.
            </p>
            <h4 className={classes.title}>Changes to our Privacy Policy</h4>
            <p>
              At our discretion, we may change our privacy policy from time to
              time. Any changes will be reflected here, so we encourage you to
              visit this page regularly. Your continued use of this site after
              any changes to this policy will be regarded as acceptance of our
              practices around privacy and personal information.
            </p>
            <h4 className={classes.title}>Business Transfers</h4>
            <p>
              If we or our assets are acquired, or in the unlikely event that we
              go out of business or enter bankruptcy, we would include user
              information among our assets transferred to or acquired by a third
              party. You acknowledge that such transfers may occur, and that any
              parties who acquire us may continue to use your personal
              information according to this policy.
            </p>
            <h4 className={classes.title}>Cookie Policy</h4>
            <p>
              We use cookies to help us improve your experience of our website,
              https://www.chilimania.com. This cookie policy is part of our
              privacy policy, and covers the use of cookies between your device
              and our site. We also provide basic information on third-party
              services we may use on our website, who may also use cookies as
              part of their service, though they are not covered by our policy.
              <br />
              <br />
              If you do not wish to accept cookies from us, you should instruct
              your browser to refuse cookies from our website, with the
              understanding that we may be unable to provide you with some of
              your desired content and services.
              <br />
              <br />
              At our discretion, we may change our privacy policy at any time.
              Any changes will be reflected here, so we encourage you to visit
              this page regularly. Your continued use of this site after any
              changes to this policy will be regarded as acceptance of our
              cookie practices as described.
            </p>
            <h4 className={classes.title}>What is a cookie?</h4>
            <p>
              A cookie is a small piece of data that a website stores on your
              device when you visit, typically containing information about the
              website itself, a unique identification number that allows the
              website to recognise your web browser when you return, additional
              data that serves the purpose of the cookie, and the lifespan of
              the cookie itself.
              <br />
              <br />
              Cookies are used to enable certain features (eg. logging in), to
              track site usage (eg. analytics), to store your user settings (eg.
              timezone, notification preferences), and to personalise your
              content (eg. advertising, language).
              <br />
              <br />
              Cookies set by the website you are visiting are normally referred
              to as “first-party cookies”, and typically only track your
              activity on that particular site. Cookies set by other sites and
              companies (ie. third parties) are called “third-party cookies”,
              and can be used to track you on other websites that use the same
              third-party service.
            </p>
            <h4 className={classes.title}>
              Types of cookies and how we use them
            </h4>
            <p>
              <b>Essential cookies</b> <br />
              Essential cookies are crucial to your experience of a website,
              enabling core features like user logins, account management,
              shopping carts and payment processing. We use essential cookies to
              enable certain functions on our website.
            </p>
            <p>
              <b>Performance cookies</b> <br />
              Performance cookies are used in the tracking of how you use a
              website during your visit, without collecting personal information
              about you. Typically, this information is anonymous and aggregated
              with information tracked across all site users, to help companies
              understand visitor usage patterns, identify and diagnose problems
              or errors their users may encounter, and make better strategic
              decisions in improving their audience’s overall website
              experience. These cookies may be set by the website you’re
              visiting (first-party) or by third-party services. We use
              performance cookies on our site.
            </p>
            <p>
              <b>Functionality cookies</b> <br />
              Functionality cookies are used in collecting information about
              your device and any settings you may configure on the website
              you’re visiting (like language and timezone settings). With this
              information, websites can provide you with customised, enhanced or
              optimised content and services. These cookies may be set by the
              website you’re visiting (first-party) or by third-party service.
              We use functionality cookies for selected features on our site.
            </p>
            <p>
              <b>Targeting/advertising cookies</b> <br />
              Targeting/advertising cookies are used in determining what
              promotional content is more relevant and appropriate to you and
              your interests. Websites may use them to deliver targeted
              advertising or to limit the number of times you see an
              advertisement. This helps companies improve the effectiveness of
              their campaigns and the quality of content presented to you. These
              cookies may be set by the website you’re visiting (first-party) or
              by third-party services. Targeting/advertising cookies set by
              third-parties may be used to track you on other websites that use
              the same third-party service. We use targeting/advertising cookies
              on our site.
            </p>
            <p>
              <b>Third-party cookies on our site</b> <br />
              We may employ third-party companies and individuals on our
              websites — for example, analytics providers and content partners.
              We grant these third parties access to selected information to
              perform specific tasks on our behalf. They may also set
              third-party cookies in order to deliver the services they are
              providing. Third-party cookies can be used to track you on other
              websites that use the same third-party service. As we have no
              control over third-party cookies, they are not covered by
              Chilimania, Inc.’s cookie policy.
            </p>
            <p>
              <b>Our third-party privacy promise</b> <br />
              We review the privacy policies of all our third-party providers
              before enlisting their services to ensure their practices align
              with ours. We will never knowingly include third-party services
              that compromise or violate the privacy of our users.
            </p>
            <h4 className={classes.title}>
              How you can control or opt out of cookies
            </h4>
            <p>
              {" "}
              If you do not wish to accept cookies from us, you can instruct
              your browser to refuse cookies from our website. Most browsers are
              configured to accept cookies by default, but you can update these
              settings to either refuse cookies altogether, or to notify you
              when a website is trying to set or update a cookie. If you browse
              websites from multiple devices, you may need to update your
              settings on each individual device.
              <br />
              <br /> Although some cookies can be blocked with little impact on
              your experience of a website, blocking all cookies may mean you
              are unable to access certain features and content across the sites
              you visit.
            </p>
          </div>
        </DialogContent>
        <DialogActions className={classes.modalPrivacyTermsMapFooter}>
          <Button
            onClick={() => setPrivacyTermsModal(false)}
            color="danger"
            simple
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
