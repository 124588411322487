import {
  faFacebookSquare,
  faInstagram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"
// @fortawesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// component styles
import customFooterStyle from "assets/jss/material-kit-pro-react/components/customFooterStyle.jsx"
// nodejs library that concatenates classes
import classNames from "classnames"
// core components
import Button from "components/CustomButtons/Button.jsx"
import Footer from "components/Footer/Footer.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
// gatsby libraries
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import ContactForm from "../ContactForm/ContactForm"
import PrivacyTerms from "../PrivacyTerms/PrivacyTerms"

const useStyles = makeStyles(customFooterStyle)

export default function CustomFooter() {
  const classes = useStyles()
  const {
    chilimaniaLogo,
    countryEdgeLogo,
    turtleneck,
  } = useStaticQuery(graphql`
    query getFooterLogos {
      chilimaniaLogo: file(
        relativePath: { eq: "logos/chilimania-logo-transparent.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      countryEdgeLogo: file(
        relativePath: { eq: "logos/country-edge-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      turtleneck: file(relativePath: { eq: "logos/turtleneck.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  return (
    <div>
      <Footer
        theme="white"
        content={
          <GridContainer
            className={classNames(classes.copyright, classes.alignCenter)}
          >
            <GridItem xs={12} sm={4}>
              Copyright &copy; {1900 + new Date().getYear()}
            </GridItem>
            <GridItem xs={12} sm={4}>
              <div className={classes.turtleneckWrapper}>
                <GatsbyImage
                  image={turtleneck.childImageSharp.gatsbyImageData}
                  className={classes.turtleneckLogo}
                  alt="Turtleneck Software Logo"
                  imgStyle={{ objectFit: "contain" }}
                />
                Turtleneck Software
              </div>
              <div className={classes.privacyModalWrapper}>
                <PrivacyTerms />
              </div>
            </GridItem>
            <GridItem xs={12} sm={4}>
              All Rights Reserved.
            </GridItem>
          </GridContainer>
        }
      >
        <div className={classes.footer}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={4}>
              <h5 className={classes.aboutTitle}>About Chilimania</h5>
              <div className={classes.aboutText}>
                Chilimania is all about supporting our youth and community.
                We’re home to the Wisconsin State Championship CASI-sanctioned
                chili cook-off, a traditional chili cook-off, a salsa contest,
                public chili tasting, best Bloody Mary competition, 12 hours of
                live music, food, fun, and more. Since 1990, the festival has
                been held annually on the Saturday after Labor Day in beautiful
                downtown, Edgerton, Wisconsin.
              </div>
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              <div className={classes.logoContainer}>
                <div className={classes.logoWrapper}>
                  <GatsbyImage
                    image={chilimaniaLogo.childImageSharp.gatsbyImageData}
                    className={classes.footerLogo}
                    alt="Chilimania Logo"
                    imgStyle={{ objectFit: "contain" }}
                  />
                </div>
                <div className={classes.logoWrapper}>
                  <GatsbyImage
                    image={countryEdgeLogo.childImageSharp.gatsbyImageData}
                    className={classes.footerLogo}
                    alt="Country Edge Logo"
                    imgStyle={{ objectFit: "contain" }}
                  />
                </div>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} className={classes.socialIconItem}>
              <h5 className={classes.footerContact}>
                <ContactForm />
              </h5>
              <h5 className={classes.followTitle}>Follow Us</h5>
              <ul className={classes.socialButtons}>
                <li>
                  <Button
                    justIcon
                    simple
                    href="https://twitter.com/Chilimania"
                    color="twitter"
                  >
                    <FontAwesomeIcon icon={faTwitter} />
                  </Button>
                </li>
                <li>
                  <Button
                    justIcon
                    simple
                    href="https://www.facebook.com/Chilimania"
                    color="facebook"
                  >
                    <FontAwesomeIcon icon={faFacebookSquare} />
                  </Button>
                </li>
                <li>
                  <Button
                    justIcon
                    simple
                    href="https://www.youtube.com/channel/UCHgU74XfEPwfcFABim72Pww"
                    color="youtube"
                  >
                    <FontAwesomeIcon icon={faYoutube} />
                  </Button>
                </li>
                <li>
                  <Button
                    justIcon
                    simple
                    href="https://www.instagram.com/chilimaniawi/"
                    color="instagram"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </Button>
                </li>
              </ul>
              <h5>Join Us</h5>
              <h4>
                6,000 <small>Anual Attendees</small>
              </h4>
              <h4>
                $200,000+ <small>Raised for Charity</small>
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Footer>
    </div>
  )
}
