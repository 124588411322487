import {
  btnLink,
  grayColor,
  title,
  twitterColor
} from "assets/jss/material-kit-pro-react.jsx"

const customFooterStyle = (theme) => ({
  aboutText: {
    color: "#777",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "20px",
      textAlign: "center",
    },
  },
  aboutTitle: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  alignCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  btnTwitter: {
    ...btnLink,
    color: twitterColor,
  },
  copyright: {
    padding: "15px 0px 0px 0px",
  },
  followTitle: {
    marginBottom: "5px !important",
  },
  footer: {
    "& ul li": {
      display: "inline-block",
    },
    "& h4, & h5": {
      color: grayColor[1],
      textDecoration: "none",
    },
    "& ul:not($socialButtons) li a": {
      color: "inherit",
      padding: "0.9375rem",
      fontWeight: "500",
      fontSize: "12px",
      textTransform: "uppercase",
      borderRadius: "3px",
      textDecoration: "none",
      position: "relative",
      display: "block",
    },
    "& small": {
      fontSize: "75%",
      color: grayColor[10],
    },
  },
  footerContact: {
    "&:hover": {
      cursor: "pointer",
    },
    "& button": {
      fontSize: "1.0625rem",
      lineHeight: "1.55em",
      fontWeight: "700",
      textTransform: "none",
      padding: "0",
    },
  },
  footerLogo: {
    maxWidth: "100%",
    maxHeight: "150px",
  },
  logoContainer: {
    margin: "20px",
  },
  logoWrapper: {
    maxWidth: "225px",
    maxHeight: "150px",
    height: "auto",
    margin: "0 auto",
  },
  mailIcon: {},
  socialButtons: {
    "& li": {
      display: "inline-block",
    },
  },
  socialIconItem: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
      textAlign: "center",
    },
  },
  title,
  turtleneckLogo: {
    height: "25px",
    width: "25px",
    margin: "0px 3px",
  },
  turtleneckWrapper: {
    margin: "0px 5px 10px 5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "400",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      margin: "10px 0",
    },
  },
  privacyModalWrapper: {
    [theme.breakpoints.down("md")]: {
      "& button": {
        padding: "0",
      },
    },
  },
})

export default customFooterStyle
