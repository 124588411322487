import React from "react"

// gatsby libraries
import { Link, useStaticQuery, graphql } from "gatsby"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// @material-ui/core icons
import ArrowRight from "@material-ui/icons/ArrowRight"
import NotificationsIcon from "@material-ui/icons/Notifications"

// page sections
import Header from "components/Header/Header.jsx"
import HeaderLinks from "components/Header/HeaderLinks.jsx"
// import SnackbarContent from "components/Snackbar/SnackbarContent.jsx"

// component styles
import customHeaderStyle from "assets/jss/material-kit-pro-react/components/customHeaderStyle.jsx"

const useStyles = makeStyles(customHeaderStyle)

export default function CustomHeader({ ...rest }) {
  const classes = useStyles()
  const { lightLogo, darkLogo } = useStaticQuery(graphql`
    query getCustomHeaderImages {
      lightLogo: file(
        relativePath: { eq: "logos/chilimania-logo-transparent-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      darkLogo: file(
        relativePath: { eq: "logos/chilimania-logo-transparent.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
    }
  `)
  return (
    <div>
      {/* <SnackbarContent
        message={
          <span>
            Register today for the new hot pepper eating contest, "Scoville
            Showdown"!
            <Link to="/scoville-showdown" className={classes.psaLink}>
              Click here for more information
              <ArrowRight />
            </Link>
          </span>
        }
        close
        color="danger"
        icon={NotificationsIcon}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      /> */}
      <Header
        color="transparent"
        brand="Chilimania"
        logoImageLight={lightLogo.childImageSharp.gatsbyImageData}
        logoImageDark={darkLogo.childImageSharp.gatsbyImageData}
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        changeColorOnScroll={{
          height: 10,
          color: "white",
        }}
        {...rest}
      />
    </div>
  )
}
