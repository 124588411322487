import React from "react"

// nodejs library to set properties for components
import PropTypes from "prop-types"
import classNames from "classnames"

// react components for routing our app without refresh
import { Link } from "gatsby"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import SvgIcon from "@material-ui/core/SvgIcon"

// @material-ui/icons
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber"
import InfoIcon from "@material-ui/icons/Info"
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer"
import QueueMusicIcon from "@material-ui/icons/QueueMusic"
import LocalDiningIcon from "@material-ui/icons/LocalDining"
import MonetizationOn from "@material-ui/icons/MonetizationOn"
import NotesIcon from "@material-ui/icons/Notes"
import SchoolIcon from "@material-ui/icons/School"
import ShoppingCart from "@material-ui/icons/ShoppingCart"
import EventNoteIcon from "@material-ui/icons/EventNote"
import LocalMallIcon from "@material-ui/icons/LocalMall"

// @fortawesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPepperHot,
  faHatCowboy,
  faHandHoldingUsd,
  faClipboardList,
  faTrophyAlt,
  faNewspaper,
  faBurgerSoda,
  faUtensils,
  faFireExtinguisher,
} from "@fortawesome/pro-solid-svg-icons"

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx"
import Button from "components/CustomButtons/Button.jsx"
import ContactForm from "components/ContactForm/ContactForm"

// component styles
import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.jsx"
const useStyles = makeStyles(styles)

export default function HeaderLinks(props) {
  // const easeInOutQuad = (t, b, c, d) => {
  //   t /= d / 2
  //   if (t < 1) return (c / 2) * t * t + b
  //   t--
  //   return (-c / 2) * (t * (t - 2) - 1) + b
  // }

  // const smoothScroll = (e, target) => {
  //   if (
  //     typeof window !== "undefined" &&
  //     window.location.pathname === "/sections"
  //   ) {
  //     var isMobile = navigator.userAgent.match(
  //       /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
  //     )
  //     if (isMobile) {
  //       // if we are on mobile device the scroll into view will be managed by the browser
  //     } else {
  //       e.preventDefault()
  //       var targetScroll = document.getElementById(target)
  //       scrollGo(document.documentElement, targetScroll.offsetTop, 1250)
  //     }
  //   }
  // }
  // const scrollGo = (element, to, duration) => {
  //   var start = element.scrollTop,
  //     change = to - start,
  //     currentTime = 0,
  //     increment = 20

  //   var animateScroll = function() {
  //     currentTime += increment
  //     var val = easeInOutQuad(currentTime, start, change, duration)
  //     element.scrollTop = val
  //     if (currentTime < duration) {
  //       setTimeout(animateScroll, increment)
  //     }
  //   }
  //   animateScroll()
  // }
  // var onClickSections = {}

  const { dropdownHoverColor } = props
  const classes = useStyles()
  return (
    <List className={classes.list + " " + classes.mlAuto}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="Info"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          buttonIcon={InfoIcon}
          dropdownList={[
            <Link to="/about" className={classes.dropdownLink}>
              <NotesIcon className={classes.dropdownIcons} />
              About
            </Link>,
            <Link to="/admissions" className={classes.dropdownLink}>
              <ConfirmationNumberIcon className={classes.dropdownIcons} />
              Admissions
            </Link>,
            // TBA
            // <Link
            //   to="https://mkapparel.chipply.com/chilimania/"
            //   className={classes.dropdownLink}
            // >
            //   <LocalMallIcon className={classes.dropdownIcons} />
            //   Apparel
            // </Link>,
            <Link to="/food-vendors" className={classes.dropdownLink}>
              <FontAwesomeIcon
                className={classNames(classes.dropdownIcons, "fa-fw")}
                icon={faBurgerSoda}
              />
              Food Vendors
            </Link>,
            <Link to="/public-tasting" className={classes.dropdownLink}>
              <FontAwesomeIcon
                className={classNames(classes.dropdownIcons, "fa-fw")}
                icon={faUtensils}
              />
              Public Tasting
            </Link>,
            <Link to="/scholarships" className={classes.dropdownLink}>
              <SchoolIcon className={classes.dropdownIcons} />
              Scholarships
            </Link>,
            <Link to="/faq" className={classes.dropdownLink}>
              <QuestionAnswerIcon className={classes.dropdownIcons} />
              FAQ
            </Link>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="Compete"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          buttonIcon={LocalDiningIcon}
          dropdownList={[
            <Link to="/chili-cook-off" className={classes.dropdownLink}>
              <FontAwesomeIcon
                className={classes.dropdownIcons}
                icon={faPepperHot}
              />
              Chili Cook-Off
            </Link>,
            <Link to="/salsa-contest" className={classes.dropdownLink}>
              <SvgIcon
                className={classes.dropdownIcons}
                fill="current-color"
                id="Capa_1"
                data-name="Capa 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 486.98 512"
              >
                <title>tomato</title>
                <path
                  fill="current-color"
                  d="M460.05,183.27c-26.06,13.86-56.61,17.87-88.21,9,3.28,35.33-7,60.36-20.56,86.5-46.63-3.05-77.13-20.7-95.27-43.19C238,258,207.51,275.74,160.73,278.8c-12.48-24-24-49.41-20.56-86.5-30.3,8.52-61.16,5.37-88.2-9.05C-7.49,267.56-2.32,385.9,85,456.37c91.58,73.87,249.6,74.48,341.93,0C514.15,386.05,519.61,267.73,460.05,183.27Zm-19.22-23.88c-50.43-50.24-106.34-74.24-170.29-77.7,0-28.12,8.14-53.07,36-51.63L308.08.11C262-2.27,240.51,35,240.51,81.74c-67.17,3.87-121.67,30.16-169.33,77.65,47.47,19,74.54-.44,120.57-24.07-16.51,37-33.22,63.82-13.39,111.39,56.62-11.6,63.7-45.54,77.65-78.23,14.69,34.44,21.88,67.2,77.63,78.28,20.62-49.3,1.91-77.18-13.46-111.63C365.43,158.53,392.83,178.55,440.83,159.39Z"
                  transform="translate(-12.51 0)"
                />
              </SvgIcon>
              Salsa Contest
            </Link>,
            // <Link to="/bloody-mary-contest" className={classes.dropdownLink}>
            //   <SvgIcon
            //     className={classes.dropdownIcons}
            //     fill="current-color"
            //     id="Layer_1"
            //     data-name="Layer 1"
            //     xmlns="http://www.w3.org/2000/svg"
            //     viewBox="0 0 319.98 512"
            //   >
            //     <title>bloody-mary</title>
            //     <path
            //       d="M414.91,10.26a16,16,0,0,0-20.67-9.19l-104,40A16,16,0,0,0,280,56V176H240a79.61,79.61,0,0,0,16-48c0-47-40.7-82.11-81.15-80l-7.18-35.21a16,16,0,0,0-31.35,6.39c7.73,38,7.37,35.57,7.27,35.67C87.89,79.63,79,155.13,128,192V496a16,16,0,0,0,16,16H352a16,16,0,0,0,16-16V192a16,16,0,0,0-16-16H312V67l93.74-36.06a16,16,0,0,0,9.18-20.67ZM280,208v65.38c-21.05-2.4-37.91-10.05-62-13.66L207.43,208ZM176,112a16,16,0,1,1-16,16A16,16,0,0,1,176,112Zm9,146a216.62,216.62,0,0,0-25,2.56V208h14.79Zm151-50v59.94a199,199,0,0,1-24,4.9V208Z"
            //       transform="translate(-96.01 0)"
            //     />
            //   </SvgIcon>
            //   Bloody Mary Contest
            // </Link>,
            <Link to="/scoville-showdown" className={classes.dropdownLink}>
              <FontAwesomeIcon
                className={classes.dropdownIcons}
                icon={faFireExtinguisher}
              />
              Scoville Showdown
            </Link>,
            <Link to="/past-winners" className={classes.dropdownLink}>
              <FontAwesomeIcon
                className={classes.dropdownIcons}
                icon={faTrophyAlt}
              />
              Past Winners
            </Link>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="Lineups"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          buttonIcon={QueueMusicIcon}
          dropdownList={[
            <Link to="/country-edge-lineup" className={classes.dropdownLink}>
              <FontAwesomeIcon
                className={classNames(classes.dropdownIcons, "fa-fw")}
                icon={faHatCowboy}
              />
              Country Edge
            </Link>,
            <Link to="/chilimania-lineup" className={classes.dropdownLink}>
              <FontAwesomeIcon
                className={classNames(classes.dropdownIcons, "fa-fw")}
                icon={faPepperHot}
              />
              Chilimania
            </Link>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="Sponsors"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          buttonIcon={MonetizationOn}
          dropdownList={[
            <Link to="/sponsor-application" className={classes.dropdownLink}>
              <FontAwesomeIcon
                className={classNames(classes.dropdownIcons, "fa-fw")}
                icon={faHandHoldingUsd}
              />
              Contribute
            </Link>,
            <Link to="/sponsor-list" className={classes.dropdownLink}>
              <FontAwesomeIcon
                className={classNames(classes.dropdownIcons, "fa-fw")}
                icon={faClipboardList}
              />
              View All
            </Link>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/schedule" className={classes.linkWrapper}>
          <Button className={classes.navLink} color="transparent">
            <EventNoteIcon />
            Schedule
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/news" className={classes.linkWrapper}>
          <Button className={classes.navLink} color="transparent">
            <FontAwesomeIcon icon={faNewspaper} />
            News
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <div className={classes.navLinkModal}>
          <ContactForm />
        </div>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/register" className={classes.linkWrapper}>
          <Button
            color={
              typeof window !== "undefined" && window.innerWidth < 960
                ? "info"
                : "primary"
            }
            target="_blank"
            className={classes.navButton}
            round
          >
            <ShoppingCart /> register
          </Button>
        </Link>
      </ListItem>
    </List>
  )
}

HeaderLinks.defaultProps = {
  hoverColor: "primary",
}

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
  ]),
}
