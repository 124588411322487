import { description, title } from "assets/jss/material-kit-pro-react.jsx"
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx"

const privacyTermsStyle = (theme) => ({
  ...modalStyle(theme),
  privacyTermsButton: {
    margin: "0 auto",
  },
  description: {
    ...description,
    "& p": {
      padding: "0 15px",
    },
    "& ol": {
      padding: "0 15px 0px 30px",
    },
  },
  cardIcon: {
    fontSize: "1rem",
    marginRight: "3px",
    "& path": {
      color: "#e4240ecc",
    },
  },
  modalPrivacyTermsMapTitle: {
    ...title,
    lineHeight: "1.5",
    textAlign: "center",
  },
  modalPrivacyTermsMapImage: {
    maxWidth: "100%",
    margin: "20px auto 50px auto",
    borderRadius: "6px",
    boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
  },
  modalPrivacyTermsMapFooter: {
    padding: "15px",
    textAlign: "right",
    margin: "0",
    justifyContent: "center",
    borderTop: "1px solid #ccc",
    paddingTop: "15px",
  },
  modalCardCategory: {
    textTransform: "uppercase",
    color: "#377358",
    display: "flex",
    alignItems: "center",
    margin: "0",
    "& path": {
      color: "#377358 !important",
    },
  },
  title: {
    ...title,
    marginBottom: "0",
  },
})

export default privacyTermsStyle
