const customHeaderStyle = (theme) => ({
  psaLink: {
    display: "flex",
    alignItems: "center",
  },
  snackbarText: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
})

export default customHeaderStyle
